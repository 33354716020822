/*--------------------------------------------------------------------------
 * Spark graphs
 * 2016-01-22
 */
.datas-text {
    font-weight: bold !important;
}

.spark-datas {
    list-style-type: none;
    padding: 0px;
    /*margin: 10px 0px;*/
}

.spark-datas li{
    display: inline-block;
    /*	margin-bottom: 5px;
        margin-right: 10px;
        padding: 10px 20px;
      background-color: #f8f8f8;
      background: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f2f2f2));
      background: -webkit-linear-gradient(top, #f8f8f8, #f2f2f2);
      background: -moz-linear-gradient(top, #f8f8f8, #f2f2f2);
      background: -ms-linear-gradient(top, #f8f8f8, #f2f2f2);
      background: -o-linear-gradient(top, #f8f8f8, #f2f2f2);
      background: linear-gradient(top, #f8f8f8, #f2f2f2);
      background: #f8f8f8 url("/media/cream.png") repeat;
      text-shadow:0px 1px #fff;
      border: 1px solid #ccc;
      box-shadow: inset 0px 0px 3px #fff;*/
    max-width: 100%;
    text-align: center;
}

.spark-datas li .datas-text{
    font-size: 11px;
    padding: 0px 0px 0px 0px;
    font-weight: normal;
}

.spark-datas li .spark{
    width: inherit;
    overflow: scroll;
}

.spark-int {
    position: relative;
    height: 0px;
    overflow: visible;
    top: -15px;

    text-align: right;

    margin: 0px !important;
    padding: 0px !important;
}

.spark canvas{
    float: right;
}